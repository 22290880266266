<template>
    <div>
        <div class="d-flex" v-if="!isStudent">
            <b-input-group >
                <div class="label-as-input">
                    {{ getObjectValue(formData.file, 'file_name') }}
                </div>

            </b-input-group>

            <div class="d-flex custom-file-upload">
                <b-button variant="outline-secondary"
                          @click="downloadFile(formData.id)">
                    <i class="ri-download-line" style="font-size:20px"></i>
                </b-button>
            </div>
        </div>
        <b-form-group :label="$t('note')">
            <div class="label-as-input height-200">
                {{ getObjectValue(formData, 'note') }}
            </div>
        </b-form-group>
    </div>
</template>

<script>
// Services
import StudentNoteService from '@/services/StudentNoteService';
import i18n from "@/plugins/i18n";
import {EventBus} from "@/main";

export default {
    props: {
        studentProgram: {
            type: Object,
            default: null
        },
        formId: {
            type: Number
        }
    },
    data() {
        return {
            formData: {
                note: null,
                file: null
            },
            formLoading: false
        }
    },
    computed:{
        isStudent() {
            return this.$store.getters["auth/isStudent"];
        },

    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            StudentNoteService.get(id)
                .then(response => {
                    let data = response.data.data;
                    this.formData = {
                        note: data.note,
                        file: data.file,
                        id: data.id,
                        name:data.name
                    }
                })
        },
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.studentProgram.student_number) {
                let formData = {
                    student_number: this.studentProgram.student_number,
                    ...this.formData
                }
                this.formLoading = true;
                StudentNoteService.update(this.formId, formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$emit('updateFormSuccess', true);
                    })
                    .catch((e) => {
                        this.showErrors(e, this.$refs.formModalValidate)
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        },
        downloadFile(id){
            StudentNoteService.downloadNote(id)
                .then(res=>{
                    let fileName = this.formData.name  +'.pdf'
                    EventBus.$emit('pdfViewerModalShow', {
                        pdfFileData: res.data,
                        pdfFileName: fileName,

                    })})
                .catch(err=>this.showErrors(err,null,true))
        }
    }
}
</script>

